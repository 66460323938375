import { API, Amplify } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import awsconfig from '../../../aws-exports.js'
Amplify.configure(awsconfig)

export default function Xero() {
  const [loading, setLoading] = useState(true)
  const [connected, setConnected] = useState(false)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const checkStatus = async () => {
      setLoading(true)
      const { status, url = null } = await API.get('wwwREST', '/v1/private/admin/xero/status?1=1', {})
      console.log({ status, url })
      setConnected(status)
      if (url) setUrl(url)
      setLoading(false)
    }

    checkStatus().catch(err => console.error(JSON.stringify(err, null, 2)))
  }, [])

  const connect = async () => {
    const url = await API.get('wwwREST', '/v1/private/admin/xero/connect?1=1', {})
    window.open(url, '_blank')
  }

  return (
    <div className="m-5 max-w-sm">
      {loading && <p>Loading...</p>}
      {!loading && connected && <p>Xero is connected</p>}
      {!loading && !connected && url && (
        <div>
          <button onClick={connect} type="button" className="btn-primary">
            Click here to connect Xero
          </button>
        </div>
      )}
    </div>
  )
}
